<template>
    <y-dropdown
        ref="Dropdown"
        class="grid-filters"
        :class="classes"
        position="bottom-left"
        @open="fetch"
    >
        <template slot="toggle">
            <y-button>{{ $t('grid.filters') }}</y-button>
            <span
                v-if="filterFieldsUsed.length"
                class="label badge red"
            >
                {{ filterFieldsUsed.length | digits($i18n.locale) }}
            </span>
        </template>

        <y-loading
            :active="$wait.is('filters-loading')"
            height="200px"
        >
            <div class="d-flex">
                <y-form
                    v-model="model"
                    :params="form"
                />

                <div class="menu-foot">
                    <y-button
                        color="blue"
                        @click="filter"
                    >
                        {{ $t('button.filter') }}
                    </y-button>
                </div>
            </div>
        </y-loading>
    </y-dropdown>
</template>

<script>

    import { YDropdown, YForm } from '@deps';

    export default {

        name: 'GridHeaderFiltersForm',

        components: {
            YDropdown,
            YForm,
        },

        props: {
            value      : Object,
            grid       : Object,
            filtersForm: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                hasFilters: false,
                model     : null,

                form: this.filtersForm,

                filterFieldsUsed: [],

                defaultQueries: [
                    'r',
                    'sort',
                    'order',
                    'refresh',
                    'tab',
                    'search',
                ],
            };
        },

        computed: {

            /**
             * Return class of filters wrapper
             */
            classes() {
                const classes = [];

                if (this.form) {
                    const count = this.filtersForm.length;
                    if (count === 1) {
                        return ['single'];
                    }
                    if (count < 4) {
                        return ['xs'];
                    }
                    if (count >= 4 && count <= 6) {
                        return ['sm'];
                    }
                    if (count >= 7 && count <= 8) {
                        return ['md'];
                    }
                    if (count > 8) {
                        return ['lg'];
                    }
                }

                return classes;
            },

        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', this.cloneDeep(newVal));
                    }
                },
                deep     : true,
                immediate: true,
            },

            /**
             * Watch for route change and clear form
             */
            $route: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'form', []);
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {

            /**
             * Fetch filters form
             */
            fetch() {
                this.$wait.start('filters-loading');

                const params = { ...this.$route.params, ...this.$route.query };

                // Fix page changes
                params.refresh = 'filters';

                this.grid.service(params).then((response) => {
                    this.form = response.data.metadata.form;
                    this.$emit('set-filters-form', this.form);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('filters-loading');
                });
            },

            /**
             * Emit filter data
             */
            filter() {
                const payload = {
                    refresh: 'list',
                    ...this.model,
                };
                this.$emit('refresh', payload);
                this.$refs.Dropdown.close();
            },
        },

    };
</script>
